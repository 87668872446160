export const firebaseConfig = {
  apiKey: "AIzaSyCFdl3Ug5FqgCUFW_JHk8_60LBa64wKJ2s",
  authDomain: "glendalesc.firebaseapp.com",
  databaseURL: "https://glendalesc.firebaseio.com",
  projectId: "glendalesc",
  storageBucket: "glendalesc.appspot.com",
  messagingSenderId: "208343735302",
  appId: "1:208343735302:web:05458ac84b69d1cbf13787",
};

export const vapidKey = `BFK8cU682XsEyL9dbsFgN87SChF80qUTTgS7AvAuvuQkElf7BvIw9aYPBdZFInjFcVcShGDwQgrg5hKaZg_O484`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}